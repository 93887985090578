// import { type DragEvent, useMemo } from 'react';
import { Box } from '@unifyapps/ui/components/Box';
import { slowCn } from '@unifyapps/ui/lib/utils';
// import Box from '@mui/material/Box';
// import TableCell, { type TableCellProps } from '@mui/material/TableCell';
import type { TableHeadCellProps as BaseTableHeadCellProps } from '@unifyapps/ui/components/Table/TableHeadCell';
import { TableHeadCell as BaseTableHeadCell } from '@unifyapps/ui/components/Table/TableHeadCell';
import { useTheme } from '@mui/material/styles';
// import { type Theme } from '@mui/material/styles';
import { clsx } from 'clsx';
import type { MRT_ColumnVirtualizer } from 'material-react-table';
import useEventCallback from '@mui/material/utils/useEventCallback';
import ChevronDown from '@unifyapps/icons/outline/ChevronDown';
import type { MRT_Header, MRT_RowData, MRT_TableInstance } from '../../types';
// import { getCommonMRTCellStyles, getMRTTheme } from '../../utils/style';
import { getCommonMRTCellStyles, parseFromValuesOrFunc } from '../../utils/utils';
import {
  getIsFirstRightPinnedColumn,
  getIsLastLeftPinnedColumn,
  reorderColumn,
} from '../../utils/column';
import TableHeadCellSortLabel from './TableHeadCellSortLabel';
// import { MRT_TableHeadCellColumnActionsButton } from './MRT_TableHeadCellColumnActionsButton';
// import { MRT_TableHeadCellFilterContainer } from './MRT_TableHeadCellFilterContainer';
// import { MRT_TableHeadCellFilterLabel } from './MRT_TableHeadCellFilterLabel';
// import { MRT_TableHeadCellGrabHandle } from './MRT_TableHeadCellGrabHandle';
// import { MRT_TableHeadCellResizeHandle } from './MRT_TableHeadCellResizeHandle';
// import { MRT_TableHeadCellSortLabel } from './MRT_TableHeadCellSortLabel';

interface TableHeadCellProps<TData extends MRT_RowData> extends BaseTableHeadCellProps {
  columnVirtualizer?: MRT_ColumnVirtualizer;
  header: MRT_Header<TData>;
  staticColumnIndex?: number;
  table: MRT_TableInstance<TData>;
}

export function TableHeadCell<TData extends MRT_RowData>({
  columnVirtualizer,
  header,
  staticColumnIndex,
  table,
  ...rest
}: TableHeadCellProps<TData>) {
  const theme = useTheme();
  const {
    getState,
    options: {
      // columnFilterDisplayMode,
      // columnResizeDirection,
      // columnResizeMode,
      enableColumnActions,
      enableColumnDragging,
      enableColumnOrdering,
      enableColumnPinning,
      enableGrouping,
      // enableMultiSort,
      layoutMode,
      muiTableHeadCellProps,
      wrapColumnHeader = false,
      tableStateStore,
      ColumnActionWrapper,
      // enableSort,
    },
    refs: { tableHeadCellRefs },
    // setHoveredColumn,
  } = table;
  // const { columnSizingInfo, density, draggingColumn, grouping, hoveredColumn, showColumnFilters } =
  //   getState();
  const { columnOrder, grouping } = getState();
  const { column } = header;
  const { columnDef } = column;
  const { columnDefType } = columnDef;

  const headCellProps = parseFromValuesOrFunc(muiTableHeadCellProps, { column, table });
  const columnDefCellProps = parseFromValuesOrFunc(columnDef.muiTableHeadCellProps, {
    column,
    table,
  });

  const tableCellProps = {
    ...headCellProps,
    ...columnDefCellProps,
    ...rest,
    className: slowCn(headCellProps?.className, columnDefCellProps?.className, rest.className),
    style: { ...headCellProps?.style, ...columnDefCellProps?.style, ...rest.style },
  };

  const isColumnHovered = tableStateStore?.use.isColumnHovered(column.id) ?? false;
  const isColumnDragged = tableStateStore?.use.isColumnDragged(column.id) ?? false;
  const { setHoveredColumnId, setDraggedColumnId } = tableStateStore?.use.actions() ?? {};

  // const { draggingBorderColor } = getMRTTheme(table, theme);

  const isColumnPinned =
    enableColumnPinning && columnDef.columnDefType !== 'group' && column.getIsPinned();

  const showColumnActions =
    (enableColumnActions || columnDef.enableColumnActions) &&
    columnDef.enableColumnActions !== false;

  const showDragHandle =
    enableColumnDragging !== false &&
    columnDef.enableColumnDragging !== false &&
    (enableColumnDragging ||
      (enableColumnOrdering && columnDef.enableColumnOrdering !== false) ||
      (enableGrouping && columnDef.enableGrouping !== false && !grouping.includes(column.id)));

  // const headerPL = useMemo(() => {
  //   let pl = 0;
  //   if (column.getCanSort()) pl += 1;
  //   if (showColumnActions) pl += 1.75;
  //   if (showDragHandle) pl += 1.5;
  //   return pl;
  // }, [showColumnActions, showDragHandle]);

  /* const draggingBorders = useMemo(() => {
      const showResizeBorder =
        columnSizingInfo.isResizingColumn === column.id &&
        columnResizeMode === 'onChange' &&
        !header.subHeaders.length;

      const borderStyle = showResizeBorder
        ? `2px solid ${draggingBorderColor} !important`
        : draggingColumn?.id === column.id
          ? `1px dashed ${theme.palette.grey[500]}`
          : hoveredColumn?.id === column.id
            ? `2px dashed ${draggingBorderColor}`
            : undefined;

      if (showResizeBorder) {
        return columnResizeDirection === 'ltr'
          ? { borderRight: borderStyle }
          : { borderLeft: borderStyle };
      }
      const draggingBorders = borderStyle
        ? {
            borderLeft: borderStyle,
            borderRight: borderStyle,
            borderTop: borderStyle,
          }
        : undefined;

      return draggingBorders;
    }, [draggingColumn, hoveredColumn, columnSizingInfo.isResizingColumn]);*/

  const handleDragEnter = useEventCallback(() => {
    // const handleDragEnter = (_e: DragEvent) => {
    //   if (enableGrouping && hoveredColumn?.id === 'drop-zone') {
    //     setHoveredColumn(null);
    //   }
    //   if (enableColumnOrdering && draggingColumn && columnDefType !== 'group') {
    //     setHoveredColumn(columnDef.enableColumnOrdering !== false ? column : null);
    //   }
    // };
    if (enableColumnOrdering && columnDefType !== 'group') {
      setHoveredColumnId?.(columnDef.enableColumnOrdering !== false ? column.id : null);
    }
  });

  const handleDragStart = useEventCallback(() => {
    // table.setDraggingColumn(column);
    // try {
    //   const tableHeadCellRef = tableHeadCellRefs.current[column.id];
    //   event.dataTransfer.setDragImage(tableHeadCellRef as HTMLElement, 0, 0);
    // } catch (e) {
    //   console.error(e);
    // }
    setDraggedColumnId?.(column.id);
  });

  const handleDragEnd = useEventCallback(() => {
    // if (hoveredColumn?.id === 'drop-zone') {
    //   column.toggleGrouping();
    // } else if (enableColumnOrdering && hoveredColumn && hoveredColumn.id !== draggingColumn?.id) {
    //   table.setColumnOrder(reorderColumn(column, hoveredColumn as MRT_Column<TData>, columnOrder));
    // }
    // table.setDraggingColumn(null);
    // setHoveredColumn(null);

    if (!tableStateStore) {
      return;
    }

    const { hoveredColumnId, draggedColumnId } = tableStateStore.getState();

    if (hoveredColumnId === 'drop-zone') {
      column.toggleGrouping();
    } else if (enableColumnOrdering && hoveredColumnId && hoveredColumnId !== draggedColumnId) {
      const hoveredColumn = table.getColumn(hoveredColumnId);
      table.setColumnOrder(reorderColumn(column, hoveredColumn, columnOrder));
    }

    setDraggedColumnId?.(null);
    setHoveredColumnId?.(null);
  });

  const HeaderElement =
    parseFromValuesOrFunc(columnDef.Header, { column, header, table }) ?? columnDef.header;

  const cell = (
    <BaseTableHeadCell
      colSpan={header.colSpan}
      data-index={staticColumnIndex}
      data-pinned={Boolean(isColumnPinned) || undefined}
      data-pinned-left-last={getIsLastLeftPinnedColumn(table, column)}
      data-pinned-right-first={getIsFirstRightPinnedColumn(column)}
      draggable={showDragHandle}
      onDragEnd={handleDragEnd}
      onDragEnter={handleDragEnter}
      onDragStart={handleDragStart}
      // @ts-expect-error todo-change need to fix ref
      ref={(node: HTMLTableCellElement) => {
        // if (node) {
        tableHeadCellRefs.current[column.id] = node;
        if (columnDefType !== 'group') {
          // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition -- need the check
          columnVirtualizer?.measureElement?.(node);
        }
        // }
      }}
      rowSpan={header.rowSpan || 1}
      {...tableCellProps}
      className={clsx('group/TableHeadCell', tableCellProps.className, {
        'flex-col justify-center': layoutMode?.startsWith('grid'),
        'hover:cursor-grab': showDragHandle,
      })}
      style={{
        ...getCommonMRTCellStyles({
          column,
          header,
          table,
          tableCellProps,
          theme,
          isHovered: isColumnHovered,
          isDragged: isColumnDragged,
        }),
        ...tableCellProps.style,
      }}
      /*sx={(theme: Theme) => ({
              '& :hover': {
                '.MuiButtonBase-root': {
                  opacity: 1,
                },
              },
              flexDirection: layoutMode?.startsWith('grid') ? 'column' : undefined,
              fontWeight: 'bold',
              overflow: 'visible',
              p:
                density === 'compact'
                  ? '0.5rem'
                  : density === 'comfortable'
                    ? columnDefType === 'display'
                      ? '0.75rem'
                      : '1rem'
                    : columnDefType === 'display'
                      ? '1rem 1.25rem'
                      : '1.5rem',
              pb:
                columnDefType === 'display'
                  ? 0
                  : showColumnFilters || density === 'compact'
                    ? '0.4rem'
                    : '0.6rem',
              pt:
                columnDefType === 'group' || density === 'compact'
                  ? '0.25rem'
                  : density === 'comfortable'
                    ? '.75rem'
                    : '1.25rem',
              userSelect: enableMultiSort && column.getCanSort() ? 'none' : undefined,
              verticalAlign: 'top',
              zIndex:
                column.getIsResizing() || draggingColumn?.id === column.id
                  ? 3
                  : column.getIsPinned() && columnDefType !== 'group'
                    ? 2
                    : 1,
              ...getCommonMRTCellStyles({
                column,
                header,
                table,
                tableCellProps,
                theme,
              }),
              ...draggingBorders,
            })}*/
    >
      <Box className="flex size-full flex-col justify-center">
        <Box
          className={clsx('Mui-TableHeadCell-Content relative flex items-center', {
            'flex-row-reverse text-end': tableCellProps.align === 'right',
            'self-center text-center': tableCellProps.align === 'center',
            'justify-between': !(tableCellProps.align === 'center') && column.getCanResize(),
            'justify-start': !(tableCellProps.align === 'center') && !column.getCanResize(),
          })}
          // sx={{
          //   alignItems: 'center',
          //   display: 'flex',
          // flexDirection: tableCellProps.align === 'right' ? 'row-reverse' : 'row',
          // justifyContent:
          //   columnDefType === 'group' || tableCellProps.align === 'center'
          //     ? 'center'
          //     : column.getCanResize()
          //       ? 'space-between'
          //       : 'flex-start',
          //   position: 'relative',
          //   width: '100%',
          // }}
        >
          <Box
            className={clsx('Mui-TableHeadCell-Content-Labels gap-xs flex items-center', {
              'cursor-pointer': column.getCanSort() && columnDefType !== 'group',
              'overflow-hidden': columnDefType === 'data',
            })}
            onClick={column.getToggleSortingHandler()}
            // sx={{
            // alignItems: 'center',
            // cursor: column.getCanSort() && columnDefType !== 'group' ? 'pointer' : undefined,
            // display: 'flex',
            // flexDirection: tableCellProps.align === 'right' ? 'row-reverse' : 'row',
            // overflow: columnDefType === 'data' ? 'hidden' : undefined,
            // pl: tableCellProps.align === 'center' ? `${headerPL}rem` : undefined,
            // }}
          >
            <Box
              className={slowCn(
                'text-tertiary Mui-TableHeadCell-Content-Wrapper font-normal',
                // {
                //   'overflow-hidden': columnDefType === 'data',
                //   'whitespace-nowrap': (columnDef.header ? columnDef.header.length : 0) < 20,
                //   'whitespace-normal': (columnDef.header ? columnDef.header.length : 0) > 20,
                // },
                wrapColumnHeader ? 'whitespace-normal break-all' : 'whitespace-nowrap',
                tableCellProps.className,
              )}
              style={{
                minWidth: `${Math.min(columnDef.header ? columnDef.header.length : 0, 4)}ch`,
              }}
              // sx={{
              //   '&:hover': {
              //     textOverflow: 'clip',
              //   },
              //   minWidth: `${Math.min(columnDef.header.length ?? 0, 4)}ch`,
              // overflow: columnDefType === 'data' ? 'hidden' : undefined,
              // textOverflow: 'ellipsis',
              //   whiteSpace: (columnDef.header.length ?? 0) < 20 ? 'nowrap' : 'normal',
              // }}
              title={columnDefType === 'data' ? columnDef.header : undefined}
            >
              {HeaderElement}
            </Box>
            {/*{column.getCanFilter() && (*/}
            {/*  <MRT_TableHeadCellFilterLabel header={header} table={table} />*/}
            {/*)}*/}
            {/*{column.getCanSort() && (*/}
            {/*  <MRT_TableHeadCellSortLabel header={header} table={table} />*/}
            {/*)}*/}
            <TableHeadCellSortLabel header={header} table={table} />
            <ChevronDown className="ColumnActionWrapper-Icon text-quaternary absolute end-0 hidden size-4" />
          </Box>
          {columnDefType !== 'group' && (
            <Box
              className="Mui-TableHeadCell-Content-Actions ms-auto flex whitespace-nowrap"
              // sx={{
              //   whiteSpace: 'nowrap',
              // }}
            >
              {/*{showDragHandle ? (*/}
              {/*  <MRT_TableHeadCellGrabHandle*/}
              {/*    column={column}*/}
              {/*    table={table}*/}
              {/*    tableHeadCellRef={{*/}
              {/*      current: tableHeadCellRefs.current[column.id],*/}
              {/*    }}*/}
              {/*  />*/}
              {/*) : null}*/}
              {/*{showColumnActions ? (*/}
              {/*  <MRT_TableHeadCellColumnActionsButton header={header} table={table} />*/}
              {/*) : null}*/}
            </Box>
          )}
          {/*{column.getCanResize() && (*/}
          {/*  <MRT_TableHeadCellResizeHandle header={header} table={table} />*/}
          {/*)}*/}
        </Box>
        {/* )} */}
      </Box>
      {/*{columnFilterDisplayMode === 'subheader' && column.getCanFilter() && (*/}
      {/*  <MRT_TableHeadCellFilterContainer header={header} table={table} />*/}
      {/*)}*/}
    </BaseTableHeadCell>
  );

  if (ColumnActionWrapper && showColumnActions) {
    return (
      <ColumnActionWrapper className="ColumnActionWrapper" column={column} table={table}>
        {cell}
      </ColumnActionWrapper>
    );
  }

  return cell;
}
